.visaCard {
    max-width: 335px;
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
        0 3px 3px 1px rgba(60, 64, 67, 0.16);
    svg {
        margin-bottom: -3px;
    }
    border-radius: 20px;
}

.visaCard.visaCard--noMax {
    max-width: 100%;
    svg {
        max-width: 100%;
        margin-bottom: -8px;
        // max-height: 0;
        // padding-bottom: 62.91%;
    }
}
