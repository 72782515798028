@import 'styles/vars2.scss';
.installerDashboardCard {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: calc(70px + 2rem);
}
.installerDashboardCard--logo {
    display: flex;
    justify-content: center;
    margin-top: calc(-70px - 2rem);
}

.privilegeTripImageContainer--bothTrips {
    display: grid;
    grid-template-rows: 1fr 50px 1fr;
}

.privilegeRewardsImageContainer {
    display: grid;
    grid-template-rows: 1fr 90px 1fr;
}

.privilegeCardOuterContainer {
    position: relative;
    .tripExtensionOnImage {
        position: absolute;
        z-index: 1;
        top: 0;
        right: -5px;
        transform: rotate(-15deg);
        background: red;
        padding: 0.2rem 0.5rem;
        border-radius: 4px;
        box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
            0 1px 3px 1px rgba(60, 64, 67, 0.16);
    }
}

.certificateContainer {
    position: relative;
    // width: calc(100% - 22px);
    // height: calc(100% - 22px);
    .certificate {
        box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
            0 1px 3px 1px rgba(60, 64, 67, 0.16);
        overflow: hidden;
        border-radius: 4px;
        background: #fff;
        // height: 100%;
    }

    .quantity {
        position: absolute;
        top: -24px;
        right: -24px;
        background: red;
        width: 48px;
        height: 48px;
        border-radius: 999px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
            0 1px 3px 1px rgba(60, 64, 67, 0.16);
        font-size: 1.4rem;
        z-index: 11;
    }
}

.orderButton {
    text-decoration: none;
    background: #ccc;
    padding: 2rem;
    color: $primary-white;
    border-radius: 4px;
    // margin-top: 0rem;
    display: inline-block;
    border: none;
    outline: none;
    appearance: none;
}

.company--NAPA {
    .orderButton {
        background: $napa-primary-blue;
    }
    .orderButton:hover {
        background: darken($napa-primary-blue, 10);
    }
}

.company--AAG {
    .orderButton {
        background: $aag-primary-blue;
    }
    .orderButton:hover {
        background: darken($aag-primary-blue, 10);
    }
}

.privilegeRewardsContainer--singleTrip {
    max-width: 600px;
    margin: 0 auto;
}

a.cardSection {
    text-decoration: none;
    font-size: 12px;
    position: relative;
    padding-right: 16px;

    i {
        font-size: 14px;
        position: absolute;
        top: -1px;
        right: 0;
    }
}

.company--NAPA {
    a.cardSection {
        color: $napa-primary-blue;
    }
    a.cardSection:hover {
        color: $napa-secondary-blue;
    }
}
.company--AAG {
    a.cardSection {
        color: $aag-primary-blue;
    }
    a.cardSection:hover {
        color: $aag-secondary-blue;
    }
}

@media screen and (min-width: $sm) {
    // .installerDashboardCard--inner {
    //     display: flex;
    //     gap: 2rem;
    // }
    .privilegeTripImageContainer--bothTrips {
        grid-template-columns: 1fr 50px 1fr;
        grid-template-rows: auto;
    }
    .privilegeRewardsImageContainer {
        grid-template-columns: 1fr 90px 1fr;
        grid-template-rows: auto;
    }
}

@media screen and (min-width: $md) {
    .installerDashboardCard {
        display: flex;
        flex-direction: row;
        gap: 4rem;
        margin: 4rem 0;
    }
    .installerDashboardCard--logo {
        margin: -4rem 0 -4rem -2rem;
    }
    .installerDashboardCard--inner {
        display: flex;
        gap: 4rem;
    }
    .privilegeTripImageContainer--bothTrips {
        grid-template-columns: 1fr 100px 1fr;
    }
    // .privilegeRewardContainer {
    //     margin: '0 auto';
    // }
    // .privilegeRewardContainer--maxWidth {
    //     max-width: 600px;
    //     margin: 0 auto;
    // }
    .privilegeRewardsImageContainer {
        grid-template-columns: 1fr 140px 1fr;
    }
}
