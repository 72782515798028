$primary-blue: #0d2a8a;
$secondary-blue: #006699;
$primary-blue-dark: #0080FF;
$primary-white: rgba(255,255,255,0.95);
$primary-black: rgba(0,0,0,0.9);
$light-gray: rgba(0,0,0,0.2);
$read-only-gray: #A7A7A7;
$disabled-background: #e8e8e8;
$disabled-text: #828282;
$success-green: #33A532;
$primary-yellow: #ffc72c;
$primary-red: #ff0000;
$primary-orange: #FF8A00;
$primary-green-dark: #2FFF69;
$primary-purple-dark: #A32FFF;
$primary-red-dark: #FF2F2F;
$borderRadius: 5px;

// Main NAPA colours
$napa-primary-blue: $primary-blue;
$napa-secondary-blue: $secondary-blue;
$napa-primary-white: $primary-white;
$napa-primary-yellow: $primary-yellow;
$napa-primary-black: $primary-black;

// Main AAG colours
$aag-primary-blue: #003360;
$aag-secondary-blue: $secondary-blue;
$aag-primary-white: $primary-white;

$sm: 35.5em;
$md: 48em;
$lg: 64em;
$xl: 80em;