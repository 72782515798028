@import 'styles/vars2';

.enrollButton {
    display: block;
    text-decoration: none;
    color: $primary-white;
    padding: 1rem;
    border-radius: 4px;
}

.tabButton {
    outline: none;
    appearance: none;
    border: none;
    background: none;
    color: rgba(0, 0, 0, 0.7);
    padding: 0.5rem 0;
    cursor: pointer;
}

.tabButton--selected {
    border-bottom: 2px solid #ccc;
}

.benefitLogoOffsetContainer {
    display: flex;
    align-items: center;
    position: relative;
    left: -4rem;
    margin: -2rem 0;
}

.filterGrid {
    display: flex;
    gap: 1rem;
    align-items: center;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.7);
    margin: 0 0 4rem 0;
    background: #fafafa;
    padding: 1rem 2rem;
    border: 1px solid #eee;
    border-radius: 999px;
    button {
        outline: none;
        appearance: none;
        border: none;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 9999px;
        padding: 0.2rem 1rem;
        cursor: pointer;
    }
    button:hover {
        background: rgba(0, 0, 0, 0.2);
    }
    button.active {
        background: $napa-primary-blue;
        color: $primary-white;
    }
    button.clear {
        background: none;
        padding: 0;
        text-decoration: underline;
        // text-transform: uppercase;
        font-size: 1.1rem;
        color: rgba(0, 0, 0, 0.7);
    }
    button.clear:hover {
        color: rgba(0, 0, 0, 0.9);
    }
}

.company--NAPA {
    .enrollButton {
        background: $napa-primary-blue;
    }
    .enrollButton:hover {
        background: darken($napa-primary-blue, 10);
    }

    .tabButton--selected {
        border-color: $napa-primary-blue;
        color: $napa-primary-blue;
    }

    .tabButton:hover {
        color: $napa-primary-blue;
    }
}

.company--AAG {
    .enrollButton {
        background: $aag-primary-blue;
    }
    .enrollButton:hover {
        background: darken($aag-primary-blue, 10);
    }
    .tabButton--selected {
        border-color: $aag-primary-blue;
        color: $aag-primary-blue;
    }
    .tabButton:hover {
        color: $aag-primary-blue;
    }
}
