.loader {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .loader--foreground,
    .loader--background {
        height: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .loader--foreground {
        // fill: #F6C02C;
        fill: rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .loader--background {
        fill: rgba(0,0,0,0.4);
         -webkit-animation: pulse 900ms infinite linear;
            -moz-animation: pulse 900ms infinite linear;
              -o-animation: pulse 900ms infinite linear;
                 animation: pulse 900ms infinite linear;
    }
}

@-webkit-keyframes pulse {
    0% {
        height: 60px;
        opacity: 1;
    }
    100% {
        height: 100px;
        opacity: 0;
    }
}

@-moz-keyframes pulse {
    0% {
        height: 60px;
        opacity: 1;
    }
    100% {
        height: 100px;
        opacity: 0;
    }
}

@-o-keyframes pulse {
    0% {
        height: 60px;
        opacity: 1;
    }
    100% {
        height: 100px;
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        height: 60px;
        opacity: 1;
    }
    100% {
        height: 100px;
        opacity: 0;
    }
}