@import 'styles/vars2';

.productContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8rem;
}

.balanceAmount {
    font-size: 2rem;
}

.productQuantityContainer {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
    .productQuantityButton {
        outline: none;
        appearance: none;
        border: 1px solid #eee;
        background: none;
        width: 3rem;
        height: 3rem;
        border-radius: 0;
        cursor: pointer;
    }
    .productQuantity {
        width: 3rem;
        height: 3rem;
        text-align: center;
        line-height: 3rem;
    }
}

.visaCardAmountButton {
    border: 1px solid #eee;
    height: 3rem;
    display: flex;
    align-items: center;
    padding: 1px calc(1rem + 1px);
    cursor: pointer;
    input {
        visibility: hidden;
        position: absolute;
    }
}

.visaCardAmountButton--selected {
    border-color: $napa-primary-blue;
    border-width: 2px;
    padding: 0 1rem;
}

.visaCardAmountOther {
    border: 1px solid #eee;
    display: inline-block;
    height: 3rem;
    padding: 0 0 0 1rem;
    input {
        outline: none;
        appearance: none;
        border: none;
    }
}

button.addToCart {
    border: none;
    border-radius: 4px;
    color: $primary-white;
    cursor: pointer;
    display: block;
    padding: 1.5rem 0;
    text-align: center;
    width: 100%;
    outline: none;
    text-decoration: none;
    font-family: inherit;
    font-size: 1.6rem;
    line-height: 3rem;
    margin-bottom: 2rem;
    // background: $primary-yellow;
}

.company--NAPA {
    button.addToCart {
        background: $napa-primary-blue;
    }
    button.addToCart:hover {
        background: darken($napa-primary-blue, 10);
    }
}
.company--AAG {
    button.addToCart {
        background: $aag-primary-blue;
    }
    button.addToCart:hover {
        background: darken($aag-primary-blue, 10);
    }
}

button.addToCart:disabled {
    background: #a7a7a7;
    cursor: not-allowed;
    color: #fff;
}
