@import 'styles/vars2';

.aspContainer {
    border-radius: 4px;
    padding: 2rem;
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
        0 1px 3px 1px rgba(60, 64, 67, 0.16);
    text-align: center;
    letter-spacing: 0.02em;
    position: relative;
    display: block;
    text-decoration: none;

    .asp__businessName {
        font-size: 2.4rem;
        color: $primary-white;
    }

    .asp__address {
        margin-top: 1rem;
    }

    .clearAsp {
        outline: none;
        appearance: none;
        border: none;
        padding: 0;
        margin: 0;
        position: absolute;
        top: -12px;
        right: -12px;
        background: $secondary-blue;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        text-align: center;
        box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
            0 1px 3px 1px rgba(60, 64, 67, 0.16);
        cursor: pointer;
        i {
            line-height: 36px;
            color: $primary-white;
        }
    }

    .clearAsp:hover {
        background: darken($secondary-blue, 10);
    }
}

.aspContainer.aspContainer--alternate {
    .asp__businessName {
        color: $primary-blue;
    }

    .asp__address {
        color: lighten($primary-blue, 20);
    }
}

.company--NAPA {
    .aspContainer {
        background-color: $napa-primary-blue;
    }

    .asp__address {
        color: lighten($napa-primary-blue, 50);
    }

    .aspContainer.aspContainer--alternate {
        background-color: #eee;
    }
    a.aspContainer:hover {
        background: darken($napa-primary-blue, 20);
    }
}

.company--AAG {
    .aspContainer {
        background-color: $aag-primary-blue;
    }

    .asp__address {
        color: lighten($aag-primary-blue, 50);
    }

    .aspContainer.aspContainer--alternate {
        background-color: #eee;
    }
    a.aspContainer:hover {
        background: darken($aag-primary-blue, 20);
    }
}
