@import 'styles/vars2';

.breadcrumbContainer {
    display: inline-block;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    border-radius: 4px;
    position: relative;
    width: 100%;
}

.breadcrumbContainer:hover .breadcrumbClose {
    display: block;
}   

.breadcrumb {
    padding: 20px 30px 20px 20px;
    position: relative;
    height: 40px;
    font-size: 1.4rem;
    line-height: 2rem;
    color: $primary-white;

    .breadcrumbTitle {
        color: lighten($secondary-blue, 40);
        font-size: 1.2rem;
    }
}

.breadcrumb.breadcrumb--leftPad {
    border-top: 2px solid $primary-white;
}

.breadcrumbArrow {
    position: absolute;
    left: 50%;
    transform: translateX(-14px);
    top: -2px;
}

.breadcrumbArrow:before,
.breadcrumbArrow:after {
    content: ' ';
    height: 0;
    position: absolute; 
    width: 0;
    border: 14px solid transparent;
}

.breadcrumbArrow:before {
    z-index: 2;
    top: -3px;
}

.breadcrumbArrow:after {
    border-top-color: $primary-white;
    z-index: 1;
}

.breadcrumbClose {
    background: #fff;
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    outline: none;
    border-radius: 50%;
    box-shadow: 0 2px 6px rgba(0,0,0,0.4);
    margin: 0;
    padding: 3px;
    height: 30px;
    cursor: pointer;
    i {
        transform: rotate(90deg);
    }
}

.breadcrumbClose:hover {
    background:  darken(#fff, 10);
}

@media screen and (min-width: 48em) {

    .breadcrumbContainer {
        width: auto;
    }
 
    .breadcrumb {
        float: left;
        padding: 10px 30px 10px 20px;
        overflow: hidden;
    }

    .breadcrumb.breadcrumb--leftPad {
        padding-left: 50px;
        margin-left: -20px;
        border: none;
    }
    
    .breadcrumbArrow {
        position: absolute;
        left: 0px;
        top: -4px;
        transform: none;
    }

    .breadcrumbArrow:before,
    .breadcrumbArrow:after {
        content: ' ';
        height: 0;
        position: absolute; 
        width: 0;
        border: 34px solid transparent;
    }

    .breadcrumbArrow:before {
        z-index: 2;
        left: -4px;
        top: initial;
    }

    .breadcrumbArrow:after {
        border-left-color: $primary-white;
        z-index: 1;
    }

    .breadcrumbClose {
        background: #fff;
        position: absolute;
        right: -15px;
        bottom: initial;
        left: initial;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        outline: none;
        border-radius: 50%;
        box-shadow: 0 2px 6px rgba(0,0,0,0.4);
        margin: 0;
        padding: 3px;
        height: 30px;
        cursor: pointer;
        i {
            transform: none;
        }
    }

    .company--NAPA {
        .breadcrumbArrow:before {
            border-left-color: $napa-primary-blue;
        }
    }

    .company--AAG {
        .breadcrumbArrow:before {
            border-left-color: $aag-primary-blue;
        }
    }

}

.company--NAPA {
    .breadcrumbContainer {
        background: $napa-primary-blue;
    }
}

.company--AAG {
    .breadcrumbContainer {
        background: $aag-primary-blue;
    }
}