@import 'styles/vars2';

.cartLine {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 40px;
    gap: 2rem;
}

.cartButton {
    position: relative;
    i {
        color: $primary-white;
    }
    .cartQuantity {
        position: absolute;
        top: -8px;
        right: -8px;
        background: $primary-red;
        width: 16px;
        height: 16px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
    }
}

.cartButton:hover {
    i {
        color: darken($primary-white, 10);
    }
}

.cartRemoveButton {
    appearance: none;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    i {
        color: rgba(0, 0, 0, 0.6);
    }
}
.company--NAPA {
    .cartRemoveButton:hover {
        i {
            color: $napa-primary-blue;
        }
    }
}
.company--AAG {
    .cartRemoveButton:hover {
        i {
            color: $aag-primary-blue;
        }
    }
}
