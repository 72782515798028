@import 'styles/vars2';

.pointsPoolingToggleContainer {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.installerPointsToggle {
    display: block;
    background: #ccc;
    border: none;
    padding: 0.5rem 1rem;
    margin: 0;
    border-radius: 4px;
    letter-spacing: 0.02em;
    cursor: pointer;
    text-decoration: none;
    color: rgba(0, 0, 0.75);
}

.installerPointsToggle--selected {
    background-color: $primary-blue;
    color: $primary-white;
}

.installerPointsToggle:not(.installerPointsToggle--selected):hover {
    background-color: #ddd;
}

.subCardThing {
    background: #eee;
    border-radius: 0 0 4px 4px;
    margin-top: -2rem;
    padding: 1rem 0 0 0;
    margin: -2rem 2rem 0 2rem;
}

.subCardThingLine {
    padding: 2rem;
}
.subCardThingLine:not(:first-of-type) {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.subCardThingLine:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.subCardThing__header {
    padding: 1rem 2rem;
    // font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 0.02em;
    color: $primary-blue;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.subCardThing__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
}

.sectionOpenButton {
    outline: none;
    appearance: none;
    border: none;
    background: none;
    display: flex;
    gap: 0.5rem;
    justify-content: end;
    align-items: center;
    width: auto;
    cursor: pointer;
    color: $primary-blue;
}
.sectionOpenButton:hover {
    color: #333;
}

.sectionOpenButton {
    i {
        transition: all 200ms;
        transform: rotate(0deg);
    }
    i.rotate {
        transition: all 200ms;
        transform: rotate(180deg);
    }
}

.privilegeEarnings__container {
    .card:not(.summary-card) {
        .grid {
            padding: 1rem;

            &:nth-of-type(odd) {
                background: $disabled-background;
            }

            &.activity-line--redemption {
                background: lighten($success-green, 5);
            }
        }
    }
}
