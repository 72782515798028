// Variable definitions
@import 'styles/vars2';

.btn-back {
    border: none;
    cursor: pointer;
    outline: none;
    position: absolute;
    left: 1rem;
    padding: 1rem;
    height: 44px;
    top: 50%;
    transform: translateY(-50%);

    i {
        color: #fff;
    }
}

.navRight {
    position: absolute;
    right: 1rem;
    top: 8px;
}

.nav__toggle {
    // position: absolute;
    // right: 1.2em;
    // top: 50%;
    // transform: translateY(-50%);
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 1rem;
    i {
        color: #fff;
    }
}

.logoLink {
    padding: 0;
}

.logo .cart {
    color: #fff;
    position: relative;

    .cartCount {
        position: absolute;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 8pt;
        border-radius: 50%;
        height: 14px;
        width: 14px;
        text-align: center;
        line-height: 14px;
        letter-spacing: 0.02em;
    }
}

.logo img {
    height: 22px;
    margin-top: 19px;
}

.nav__container {
    background: black;
    padding: 1rem;
    position: fixed;
    top: 8rem;
    right: 0;
    bottom: 0;
    opacity: 1;
    z-index: 1;
    transform: translateX(0);
    transition: transform 0.2s;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow-y: auto;

    .navDivider {
        // text-align: left;
        text-transform: uppercase;
        color: $primary-white;
        padding: 0 2rem;
        margin: 2rem 0;
        font-size: 1.3rem;
        color: rgba(255, 255, 255, 0.5);
        // color: lighten($napa-primary-blue, 40);
        letter-spacing: 0.02em;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 1rem;
    }

    .navDivider::before,
    .navDivider::after {
        content: '';
        flex-grow: 1;
        height: 1px;
    }
    .navDivider::before {
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.1),
            rgba(255, 255, 255, 0)
        );
    }
    .navDivider::after {
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.1),
            rgba(255, 255, 255, 0)
        );
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            display: block;
            margin: 0;
            padding: 0;
            padding: 0 2rem;
        }
    }

    button,
    a {
        letter-spacing: 0.02em;
        background: none;
        border: none;
        outline: none;
        color: $primary-white;
        font-size: 1.6rem;
        padding: 1.5rem 2rem;
        cursor: pointer;
        text-decoration: none;
        font-family: inherit;
        display: inline-block;
        text-align: left;
        width: 100%;
        box-sizing: border-box;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.1);
        margin: 0.5rem 0;
        line-height: 24px;
        position: relative;
        border: 1px solid rgba(0, 0, 0, 0);
        i {
            vertical-align: bottom;
            margin-right: 1rem;
            color: rgba(255, 255, 255, 0.4);
            // position: absolute;
            // right: 1rem;
        }
    }
    button:hover,
    a:hover {
        color: darken($primary-white, 20);
        background-color: rgba(0, 0, 0, 0.2);
    }

    a.active {
        background: rgba(0, 0, 0, 0.3);
    }

    button.highlight,
    a.highlight {
        border-color: $success-green;
    }
}

.nav__container--hidden {
    transform: translateX(100%);
}

.nav__container button.myProgramsButton {
    text-align: center;
    display: block;
    padding: 2rem 4rem;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    img {
        height: 40px;
        display: inline-block;
        margin-bottom: 1rem;
    }
}

@media screen and (min-width: 35.5em) {
    .logo img {
        height: 40px;
        margin-top: 10px;
    }
}

// // In pixel values, this breakpoint translates to min-width of 568px and max-width of 767px
// @media screen and (min-width:35.5em) and (max-width:47.938em) {
//     .nav__container {
//         width: 100vw;
//     }
// }

// // // This breakpoint translates to min-width of 768px and max-width of 1023px
// @media screen and (min-width:48em) and (max-width:63.938em) {
//     .nav__container {
//         width: 40vw;
//     }
// }

// // // This breakpoint translates to min-width of 1024px and max-width of 1279px
// @media screen and (min-width: 64em) and (max-width:79.938em) {
//     .nav__container {
//         width: 40vw;
//     }
// }

// // // This breakpoint translates to min-width of 1280px
// @media screen and (min-width:80em) {
//     .nav__container {
//         width: 30vw;
//     }
// }

// @media screen and (min-width:120em) {
//     .nav__container {
//         width: 20vw;
//     }
// }

.company--NAPA {
    .btn-back {
        background: $napa-primary-blue;

        i {
            background: $napa-primary-blue;
        }
    }

    .logo .cart {
        .cartCount {
            color: $napa-primary-blue;
        }
    }

    .nav__container {
        background: $napa-primary-blue;

        a.active {
            color: lighten($napa-primary-blue, 60);
            i {
                color: lighten($napa-primary-blue, 30);
            }
        }

        .myProgramsButton {
            background: darken($napa-primary-blue, 10);
        }
        .myProgramsButton:hover {
            background: darken($napa-primary-blue, 15);
        }
    }
}

.company--AAG {
    .btn-back {
        background: $aag-primary-blue;

        i {
            background: $aag-primary-blue;
        }
    }

    .logo .cart {
        .cartCount {
            color: $aag-primary-blue;
        }
    }

    .nav__container {
        background: $aag-primary-blue;

        .myProgramsButton {
            background: lighten($aag-primary-blue, 75);
            color: $aag-primary-blue;
        }
        .myProgramsButton:hover {
            background: lighten($aag-primary-blue, 70);
            color: $aag-primary-blue;
        }
        a.active {
            color: lighten($aag-primary-blue, 60);
            i {
                color: lighten($aag-primary-blue, 30);
            }
        }
    }
}
