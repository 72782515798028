@import 'styles/vars2';

.resourceContainer {
    display: flex;
    gap: 2rem;
}

.resourceButton {
    display: block;
    background: rgba(0, 0, 0, 0.2);
    padding: 1rem 2rem;
    text-decoration: none;
    color: $primary-white;
    border-radius: 4px;
    line-height: 24px;
    // letter-spacing: 0.02em;
    i {
        vertical-align: bottom;
        margin-right: 1rem;
        color: rgba(255, 255, 255, 0.4);
    }
}

.company--NAPA {
    .resourceButton {
        background-color: $napa-primary-blue;
    }
    .resourceButton:hover {
        background-color: darken($napa-primary-blue, 10);
    }
}

.company--AAG {
    .resourceButton {
        background-color: $aag-primary-blue;
    }
    .resourceButton:hover {
        background-color: darken($aag-primary-blue, 10);
    }
}
