.refreshContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1.2rem 0;
  text-align: center;
  background: #fff3cd;
  border-bottom: 1px solid #ffeeba;
  z-index: 1000;
  color: #856404;
  letter-spacing: 0.02em;
  font-size: 1.3rem;
}
.refreshContainer button {
  outline: none;
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: 1.3rem;
}
.refreshContainer button:hover {
  color: #b78905;
}
