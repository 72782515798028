@import 'styles/vars2';

.card {
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
        0 1px 3px 1px rgba(60, 64, 67, 0.16);
    padding: 2rem;
    margin: 1rem 0;
    font-size: 1.4rem;
    // overflow: hidden;
    text-decoration: none;
    color: $primary-black;
    display: block;
    appearance: none;
    outline: none;
    background: none;
    border: none;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    text-align: left;
    z-index: 1;
    background: #fff;
}

.cardStatus {
    position: absolute;
    width: 4px;
    height: 100%;
    border-radius: 4px 0 0 4px;
}

.company--NAPA {
    .cardStatus.cardStatus--CHECKING {
        background: $napa-primary-blue;
    }
}
.company--AAG {
    .cardStatus.cardStatus--CHECKING {
        background: $aag-primary-blue;
    }
}

.cardSection {
    font-size: 1.5rem;
    margin: 1rem 0;
}

.cardSection__title {
    font-weight: normal;
    font-size: 1.2rem;
    letter-spacing: 0.02em;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
}

.card--vehicle {
    display: grid;
    grid-template-columns: 110px auto;
    padding: 0px;
}

.company--NAPA {
    .cardSection .cardSection__title {
        color: $napa-primary-blue;
    }
}

.company--AAG {
    .cardSection__title {
        color: $aag-primary-blue;
    }
}

.card__image {
    height: 100%;
    background: #eee;
    position: relative;
    text-align: center;
    overflow: hidden;
    border-radius: 4px 0 0 4px;
    i {
        // height: 100%;
        font-size: 3.6rem;
        line-height: 8rem;
        color: rgba(0, 0, 0, 0.4);
    }
    // border-right: 2px solid $secondary-blue;
}

.card__text {
    padding: 2rem;

    h2 {
        font-size: 1.6rem;
        line-height: 4rem;
    }
}

.card__header {
    padding-bottom: 2rem;
    font-size: 2rem;
    color: $primary-black;
}

.card--alternate {
    color: $primary-white;
    h1 {
        color: $primary-white;
        text-transform: uppercase;
        text-align: center;
    }
}

.company--NAPA {
    .card--alternate {
        background-color: $napa-primary-blue;
        .cardSection {
            .cardSection__title {
                color: lighten($napa-primary-blue, 50);
            }
        }
    }
}
.company--AAG {
    .card--alternate {
        background-color: $aag-primary-blue;
        .cardSection {
            .cardSection__title {
                color: lighten($aag-primary-blue, 50);
            }
        }
    }
}

.card--settings {
    padding: 2rem;
    line-height: 20px;
    .cardSettings__title {
        letter-spacing: 0.02em;
        color: lighten($primary-blue, 20);
        font-weight: bold;
        margin-bottom: 0.5rem;
        line-height: initial;
    }
}

.cardEditButton {
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    outline: none;
    appearance: none;
    background: #ddd;
    color: $primary-black;
    border: none;
    text-align: center;
    top: -18px;
    right: -18px;
    cursor: pointer;
    i {
        line-height: 36px;
    }
}
.cardEditButton:hover {
    background: #ccc;
}

.card--button {
    cursor: pointer;
}
.card--button:hover {
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.18),
        0 1px 3px 2px rgba(60, 64, 67, 0.26);
}

.cardTitle {
    .title {
        color: $primary-blue;
        font-weight: bold;
        font-size: 1.2rem;
        letter-spacing: 0.02em;
        margin-bottom: 0.5rem;
    }
}

.cardIcon {
    background: rgba(0, 0, 0, 0.05);
    margin: -2rem 2rem -2rem -2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px 0 0 4px;
    i {
        color: rgba(0, 0, 0, 0.5);
        font-size: 28px;
    }
}

.cardUserIcon {
    background: #fafafa;
    margin: -2rem 2rem -2rem -2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    border-radius: 4px 0 0 4px;
}

.cardDate {
    border-radius: 4px 0 0 4px;
    background: #fafafa;
    margin: -2rem 1rem -2rem -2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.08em;
    font-weight: bold;
    font-size: 1.1rem;
    line-height: 2rem;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.75);
    div.day {
        font-size: 1.4rem;
    }
}

.cardDate--past {
    background-color: $success-green;
    color: lighten($success-green, 55);
}

.cardDate--present {
    background-color: $secondary-blue;
    color: lighten($secondary-blue, 55);
}

.timeline {
    width: 4px;
    height: 20px;
    margin: -1rem 3.2rem;
    background: rgba(0, 0, 0, 0.1);
}

.timelineYear {
    margin-left: 3.2rem;

    .timelineYearLine {
        background: rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 4px;
        display: inline-block;
        vertical-align: middle;
        // margin-top: -.1rem;
    }

    .year {
        display: inline-block;
        background: rgba(0, 0, 0, 0.1);
        padding: 0 0.8rem;
        line-height: 2.4rem;
        border-radius: 5px;
        font-weight: bold;
        letter-spacing: 0.02em;
        color: rgba(0, 0, 0, 0.6);
        vertical-align: middle;
    }
}

.timeline--past,
.timelineYear--past .timelineYearLine,
.timelineYear--past .year {
    background-color: lighten($success-green, 30);
    color: darken($success-green, 20);
}

.timeline--present,
.timelineYear--present .timelineYearLine,
.timelineYear--present .year {
    background-color: lighten($secondary-blue, 40);
    color: darken($secondary-blue, 10);
}

.subCardSection {
    background: rgb(241 245 249 / 0.8);
    padding: 2rem 2rem 1rem 2rem;
    margin-top: -2rem;
    z-index: 0;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
        0 1px 3px 1px rgba(60, 64, 67, 0.16);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 3fr;
    gap: 2rem;
    // text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.75);
}

.customerCard:hover {
    position: relative;
    background: #fafafa;
    // transform: scale3d(1.01, 1.01, 1.01);
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
        0 1px 6px 1px rgba(60, 64, 67, 0.16);
    transition: all 50ms;
}

@media screen and (min-width: 64em) {
    .card--settings {
        height: 140px;
    }
}
