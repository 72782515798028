@import 'styles/vars2';

.businessInformation__container {
    position: relative;
    .businessInformation__registrationLocked {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 185, 185, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        border-radius: 4px;
        font-size: 2rem;
        color: red;
    }
}

.autocomplete__infoContainer {
    display: flex;
    justify-content: space-between;
}

.pointsPooling {
    appearance: none;
    outline: none;
    border: none;
    padding: 2rem 4rem;
    color: $primary-white;
    font-size: 1.6rem;
    cursor: pointer;
}

.pointsPooling.pointsPooling--no {
    background: rgba(0,0,0,0.5);
    border-radius: 4px 0 0 4px;
}
.pointsPooling.pointsPooling--no:hover {
    background: rgba(0,0,0,0.4)
}

.pointsPooling.pointsPooling--yes {
    border-radius: 0 4px 4px 0;
}

.purchaseAccount__wrapper {
    padding: 0;
    margin: 0;
    list-style: none;
}

.purchaseAccount__container {
    box-shadow: 0 1px 1px 0 rgb(60 64 67 / 8%), 0 1px 3px 1px rgb(60 64 67 / 16%);
    display: grid;
    grid-template-columns: 2fr 1fr 1rem;
    padding: 2rem;
    border-radius: 4px;
    margin-bottom: 2rem;
    position: relative;
}

label.store__accountsReceivable,
.fieldContainer.store__accountsReceivable,
.fieldContainer.fieldContainer--error.store__accountsReceivable {
    margin-bottom: 0;
}

.storeInfo__container {
    display: flex;
    align-items: center;

    .storeAddress {
        font-size: 1.4rem;
        color: rgba(0,0,0,0.7);
    }
}

.totalTravellers {
    background: #f9f9f9;
    padding: 1rem;
    border-radius: 4px;
    color: rgba(0,0,0,0.7);
    letter-spacing: 0.02em;
    font-size: 1.4rem;
    // text-transform: uppercase;
    border: 1px solid #ddd;
    margin-bottom: 3rem;
    text-align: right;
    font-weight: bold;
}

.clearCustomerAccountNumber {
    outline: none;
    appearance: none;
    background: #eee;
    border: none;
    width: 36px;
    height: 36px;
    text-align: center;
    color: $primary-white;
    border-radius: 50%;
    position: absolute;
    right: -18px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    i {
        line-height: 36px;
    }
}

.mapContainer {
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    overflow: hidden;

    #map {
        z-index: 0;
    }

    .airportInfo {
        padding: 1.5rem 0;
        text-align: center;
        color: $primary-white;
        font-size: 2rem;

        .airportData {
            font-size: 1.4rem;
            margin-top: .8rem;
            text-transform: uppercase;
        }
    }
}

.note {
    background: #eee;
    padding: 2rem;
    border-radius: 4px;
    text-align: center;
    border: 1px solid #ccc;
}

.note.note--exclaim {
    margin-top: 1rem;
    color: red;
    background-color: lighten(red, 42);
    border-color: lighten(red, 30);
}

.fakeField {
    position: relative;
    border: 1px solid rgba(0,0,0,0.1);
    height: 40px;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    .fakeFieldLabel {
        position: absolute;
        left: 1.3rem;
        right: 1.3rem;
        max-height: 100%;
        color: rgba(0,0,0,0.6);
        font-size: 1.2rem;
        line-height: 2.2rem;
    }
    .fakeFieldContent {
        position: absolute;
        padding-top: 1rem;
        line-height: 3rem;
        text-indent: 1.3rem;
        font-size: 1.3rem;
    }
}

.fakeField--disabled {
    background: rgba(0,0,0,0.08);
}

.company--NAPA {
    .pointsPooling.pointsPooling--yes {
        background: $napa-primary-blue;
    }
    .pointsPooling.pointsPooling--yes:hover {
        background: lighten($napa-primary-blue,10);
    }

    .clearCustomerAccountNumber {
        background: $napa-primary-blue;
    }
    .clearCustomerAccountNumber:hover {
        background: darken($napa-primary-blue, 10);
    }

    .mapContainer .airportInfo {
        background: $napa-primary-blue;
    }

    .airportData {
        color: lighten($napa-primary-blue, 55);
    }
}

.company--AAG {
    .pointsPooling.pointsPooling--yes {
        background: $aag-primary-blue;
    }
    .pointsPooling.pointsPooling--yes:hover {
        background: lighten($aag-primary-blue,10);
    }

    .clearCustomerAccountNumber {
        background: $aag-primary-blue;
    }
    .clearCustomerAccountNumber:hover {
        background: darken($aag-primary-blue, 10);
    }

    .mapContainer .airportInfo {
        background: $aag-primary-blue;
    }

    .airportData {
        color: lighten($aag-primary-blue, 55);
    }
}