@import 'styles/vars2';

.fieldContainer {
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 4px;
    margin-bottom: 1.5rem;
    position: relative;
    height: 40px;
    // z-index: 0;

    .field__label {
        font-size: 1.5rem;
        color: rgba(0,0,0,0.6);
        line-height: 40px;
        position: absolute;
        left: 1.3rem;
        right: 1.3rem;
        transition: all 150ms ease-in-out;
        text-align: left;
        max-height: 100%;
        overflow: hidden;
    }

    .field__required {
        color: red;
    }

    .field__optional {
        font-size: 1.2rem;
        color: rgba(0,0,0,0.5);
        letter-spacing: 0.02em;
    }

    .field__helper {
        font-size: 1.2rem;
        color: rgba(0,0,0,0.4);
        text-align: right;
        position: absolute;
        left: 1.3rem;
        right: 1.3rem;
        line-height: 40px;
        transition: all 150ms ease-in-out;
    }

    input,
    select,
    textarea {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        line-height: 3rem;
        background: none;
        border: none;
        outline: none;
        appearance: none;
    }

    input {
        text-indent: 1.3rem;
    }
    select {
        padding-left: 1.3rem;
        padding-right: 1.3rem;
    }
    textarea {
        padding: 1.3rem;
        line-height: initial;
        padding-top: 2rem;
        box-sizing: border-box;
        resize: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: none;
        border-radius: 4px;
        -webkit-text-fill-color: #000;
        -webkit-box-shadow: 0 0 0px 1000px rgba(0,0,0,0.0) inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    .checkbox {
        display: inline-block;
        position: absolute;
        left: 1.3rem;
        text-indent: 0px;
        // z-index: ;
        i {
            line-height: 40px;
            color: rgba(0,0,0,0.6);
        }
    }

    .checkbox.checkbox--checked {
        i {
            color: $secondary-blue;
        }
    }
    input[type="checkbox"] {
        cursor: pointer;
    }

}

.fieldContainer--textarea {
    height: 120px;
}

.fieldContainer--checkbox {
    text-indent: 3.3rem;
    cursor: pointer;
    .field__label {
        color: $primary-black;
    }
}

.fieldContainer--focused:not(.fieldContainer--checkbox),
.fieldContainer--filled:not(.fieldContainer--checkbox) {

    .field__label {
        line-height: 2.2rem;
        font-size: 1.2rem;
        transition: all 100ms ease-in-out;
    }

    .field__helper {
        line-height: 2.2rem;
        font-size: 1rem;
        transition: all 100ms ease-in-out;
    }

    input {
        padding-top: 1rem;
    }

    select {
        padding-top: 1.4rem;
    }

}

.fieldContainer--error {
    border-color: lighten(red, 30);
    background: lighten(red, 45);
    margin-bottom: 3rem;
    .field__error {
        position: absolute;
        bottom: -2rem;
        color: red;
        font-size: 1.2rem;
    }
}
.field__error.custom {
    // position: absolute;
    // bottom: -2rem;
    color: red;
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.fieldContainer--disabled {
    background: rgba(0,0,0,0.08);
}

.submitButton {
    background: $primary-yellow;
    padding: 1.2rem 3rem;
    outline: none;
    appearance: none;
    display: inline-block;
    border:none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.6rem;
    position: relative;
}

.submitButton:disabled {
    background: rgba(0,0,0,0.4);
    color: rgba(255, 255, 255, 0.8);
    cursor: auto;
}
.submitButton--loading:disabled {
    color: rgba(255,255,255,0.0);
}

fieldset {

    border: none;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;

    legend {

      color: $secondary-blue;
      text-align: left;
      font-size: 1.8rem;
      margin: 0;
      margin-bottom: 1rem;
      padding: 0;

    }

  }

.formLoader,
.formLoader:after {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}
.formLoader {
  font-size: 10px;
  position: absolute;
  top: calc(50% - 1.2rem);
  left: calc(50% - 1rem);
  text-indent: -9999em;
  border-top: .2em solid rgba(255, 255, 255, 0.2);
  border-right: .2em solid rgba(255, 255, 255, 0.2);
  border-bottom: .2em solid rgba(255, 255, 255, 0.2);
  border-left: .2em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 .8s infinite linear;
  animation: load8 .8s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.company--NAPA {
    .fieldContainer--focused {
        border-color: lighten($napa-primary-blue,20);
    }

    .submitButton {
        background: $primary-yellow;
        color: $napa-primary-blue;
    }

    .submitButton:hover {
        background: darken($primary-yellow, 10);
    }

    .submitButton--loading:hover:disabled {
        background: lighten($primary-yellow, 20);
    }
}

.company--AAG {
    .fieldContainer--focused {
        border-color: lighten($aag-primary-blue,20);
    }

    .submitButton {
        background-color: $aag-primary-blue;
        color: $aag-primary-white;
    }

    .submitButton:hover {
        background: darken($aag-primary-blue, 10);
    }

    .submitButton--loading:hover:disabled {
        background: lighten($aag-primary-blue, 20);
    }
}
