@import 'styles/vars2';

.pill {
    padding: 0.2rem 0.6rem;
    background: rgba(0, 0, 0, 0.1);
    display: inline-block;
    border-radius: 1000px;
    font-size: 1.1rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;
}

.pill.pillVariant--success {
    background-color: $success-green;
    color: $primary-white;
}

.pill.pillVariant--rejected {
    background-color: $primary-red;
    color: $primary-white;
}

.pill.pillVariant--checking {
    background-color: $primary-yellow;
    color: $primary-white;
}

.company--NAPA {
    .pill.pillVariant--secondary {
        background-color: $napa-primary-blue;
        color: $primary-white;
    }
}

.company--AAG {
    .pill.pillVariant--secondary {
        background-color: $aag-primary-blue;
        color: $primary-white;
    }
}
