@import "styles/vars2";

.noData-action--container {
    margin-top: 2rem;
}

.noData {
    // position: absolute;
    position: relative;
    top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    font-size: 2rem;
    color: rgba(0,0,0,0.6);
    padding: 4rem 0rem;
    // border: 2px dashed rgba(0,0,0,0.1);
    border-radius: 10px;
    text-align: center;
    line-height: 3rem;

}

.btn-new-installer-request {
    margin: 0 auto;
    width: 100%;
}

@media screen and (min-width: 48em) {
    .noData {
        padding: 4rem 8rem;
    }

    .btn-new-installer-request {
        width: 33%;
    }
}

.company--NAPA {
    .noData {
        a {
            color: $napa-primary-blue;
        }

        a:hover {
            color: darken($napa-primary-blue, 10);
        }
    }
}

.company--AAG {
    .noData {
        a {
            color: $aag-primary-blue;
        }

        a:hover {
            color: darken($aag-primary-blue, 10);
        }
    }
}