@import 'styles/vars2';
.checkoutLine {
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0;
}

.checkoutQuantity {
    position: absolute;
    top: -8px;
    right: -8px;
    background: #eee;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    text-align: center;
    line-height: 16px;
    font-size: 12px;
    box-shadow: 0 0 4px rgb(0 0 0 / 30%);
}

.checkoutTotalContainer {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    align-items: baseline;
    padding: 2rem 0;
    font-weight: bold;
    color: $primary-black;

    .checkoutTotalGroupContainer {
        text-align: right;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .checkoutTotalTitle {
        font-size: 1.8rem;
        line-height: 3rem;
    }

    .checkoutTotalAmount {
        font-size: 2.5rem;
    }
}

.company--NAPA {
    .redeemableBalance {
        color: $napa-primary-blue;
    }
    .redeemableBalance--error {
        color: red;
    }
}
.company--AAG {
    .redeemableBalance {
        color: $aag-primary-blue;
    }
    .redeemableBalance--error {
        color: red;
    }
}
