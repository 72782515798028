.autocomplete__wrapper {
    position: relative;
}

.autocompleteList__container {
    overflow: hidden;
    max-height: 450px;
    position: absolute;
    top: 42px;
    background: #fefefe;
    z-index: 1;
    box-shadow: 
        0 1px 1px 0 rgba(60,64,67,.08),
        0 1px 3px 1px rgba(60,64,67,.16)
    ;
    border-radius: 4px;
    width: 100%;

    .autocompleteList__loading {
        height: 100px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        max-height: inherit;
        overflow-y: auto;

        li {
            padding: 1.3rem;
            cursor: pointer;

            display: flex;
            border-bottom: 1px solid #eee;

            .autocomplete__icon {
                width: 60px;
                text-align: center;
                i {
                    line-height: 40px;
                    color: rgba(0,0,0,0.7);
                }
            }

            .aspAddress {
                font-size: 1.2rem;
                margin-top: .5rem;
                color: rgba(0,0,0,0.65);
            }

            .customerSearch__address,
            .customerSearch__contact {
                font-size: 1.2rem;
                margin-top: .5rem;
                color: rgba(0,0,0,0.65);
            }

            .autocomplete__description {
                display: flex;
                align-items: center;
                padding: 0 2rem;
                font-size: 1.1rem;
                text-transform: uppercase;
                letter-spacing: 0.02em;
                color: rgba(0,0,0,0.75);
                text-align: right;
            }

        }

        li.hasIcon {
            display: grid;
            grid-template-columns: 60px auto;
        }

        li.zeroPadding {
            padding: 1.3rem 0;
        }

        li.notFound {
            color: rgba(0,0,0,0.7);
            font-size: 1.4rem;
        }

        li:not(.noClick):hover, li.selected {
            background: rgba(0,0,0,0.05);
        }

        li.noClick {
            cursor: auto;
        }

        li.autocomplete--disabled {
            color: rgba(0,0,0,0.2);
            // NOTE: This disables interactivity when a registration lock is in place
            pointer-events: none;
            .autocomplete__icon {
                i {
                    color: rgba(0,0,0,0.2);
                }
            }
            .aspAddress {
                color: rgba(0,0,0,0.2);
            }
        }

        li.autocomplete--isRegistered {
            // background: lighten(green, 70);
            color: rgba(0,0,0,0.4);
            pointer-events: none;
            .aspAddress {
                color: rgba(0,0,0,0.4);
            }
            .autocomplete__icon {
                i {
                    color: green;
                }
            }
        }

    }

}