@import 'styles/vars2';

.privilege__progressBarTitle {
    text-align: left;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    span {
        font-size: 1.1rem;
        color: rgba(0, 0, 0, 0.7);
    }
}

.privilege__progressBarContainer {
    background: rgba(0, 0, 0, 0.2);
    height: 10px;
    border-radius: 9000px;
    position: relative;
    .privilege__progressBar {
        background: $primary-red;
        height: 100%;
        width: 0%;
        transition: all 0.5s;
        max-width: 100%;
        border-radius: 9000px;
    }
    .privilege__progressBar.privilege__progressBar--partial {
        background-color: lighten(#001e51, 20);
    }
    .privilege__progressBar.privilege__progressBar--full {
        background-color: $success-green;
    }

    .progressBarStop {
        position: absolute;
        color: rgba(0, 0, 0, 0.6);
        left: 0;
        transform: translateX(-100%);
        border-right: 1px dashed rgba(0, 0, 0, 0.5);
        padding-top: 0.5rem;
        padding-right: 0.5rem;
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        font-weight: bold;
        i {
            margin-left: 0.5rem;
        }
    }

    .progressBarStop.progressBarStop--left {
        border-right: none;
        border-left: 1px dashed rgba(0, 0, 0, 0.5);
        padding-right: 0;
        padding-left: 0.5rem;
        transform: translateX(0);
        // right: 0;
    }

    .progressBarStop.progressBarStop--right {
        border-right: 1px dashed rgba(0, 0, 0, 0.5);
        padding-right: 0.5rem;
        border-left: none;
        transform: translateX(-100%);
    }

    .progressBarStop--earned {
        color: $success-green;
    }
}
