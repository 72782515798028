@import "styles/vars2";

.pointsPooling__container {
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    text-align: center;
    color: $primary-white;
    letter-spacing: 0.02em;
    margin-top: 1rem;

    .pointsPooling__title {
        text-transform: uppercase;
        margin-bottom: 1rem;
        font-size: 1.2rem;
    }

    .pointsPooling__header {
        font-size: 2rem;
    }

    .pointsPooling__address {
        font-size: 1.4rem;
        margin-top: 1rem;
    }
}

.pointsPoolingTable__container {
    position: relative;
    margin-bottom: 3rem;
}

.pointsPooling__expander {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -12px;
    appearance: none;
    outline: none;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
    padding: 0;
    margin: 0;
    box-shadow: 0 3px 3px 0px rgba(0,0,0,0.4);
    cursor: pointer;
    i {
        color: $primary-white;
        transform: rotate(0deg);
        transition: all 250ms;
    }
}

.pointsPooling__expander.pointsPooling__expander--expanded {
    i {
        transform: rotate(180deg);
        transition: all 250ms;
    }
}

.company--NAPA {
    .pointsPooling__container {
        background: $napa-primary-blue;

        .pointsPooling__title {
            color: lighten($napa-primary-blue, 50);
        }

        .pointsPooling__address {
            color: lighten($napa-primary-blue, 60);
        }
    }

    .pointsPooling__expander {
        background: $napa-primary-blue;
    }
}

.company--AAG {
    .pointsPooling__container {
        background: $aag-primary-blue;

        .pointsPooling__title {
            color: lighten($aag-primary-blue, 50);
        }

        .pointsPooling__address {
            color: lighten($aag-primary-blue, 60);
        }
    }

    .pointsPooling__expander {
        background: $aag-primary-blue;
    }
}