@import 'styles/vars2';

.installerBalance {
    font-size: 1.8rem;
}

.company--NAPA {
    .installerBalance {
        color: $napa-primary-blue;
    }
}

.company--AAG {
    .installerBalance {
        color: $aag-primary-blue;
    }
}
