@import 'styles/vars2';

.privilegeBenefit__container {
    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // background: linear-gradient(104.88deg, #001e51 0%, #000537 96.64%);
    // z-index: 999;
    // display: flex;
    // justify-content: center;
    // // align-items: center;
    // // padding: 8rem;
    // padding: 8rem 0;
    // color: $primary-white;
    // overflow-y: auto;

    .privilegeOverlay__close {
        appearance: none;
        outline: none;
        border: none;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        color: $primary-white;
        position: fixed;
        right: 2rem;
        top: 2rem;
        cursor: pointer;
        z-index: 1;
        background: lighten(#001e51, 20);
        i {
            line-height: 36px;
        }
    }

    .privilegeOverlay__close:hover {
        background: lighten(#001e51, 20);
    }

    .privilege {
        text-align: center;
        width: 100%;
    }

    form {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    section {
        min-height: 40vh;
        // min-height: 15rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 4rem;
        box-sizing: border-box;
    }

    section.benefit {
        // background: #fafafa;
        // position: relative;
        // margin-top: 260px;
        width: 100%;
        align-items: initial;
        color: #001e51;

        h1 {
            position: absolute;
            top: -400px;
            margin: 0;
            font-size: 6rem;
            color: $primary-white;
        }

        h2 {
            color: #001e51;
            text-transform: uppercase;
            font-size: 3rem;
        }
        h3 {
            color: rgba(0, 0, 0, 0.6);
            font-size: 1.8rem;
            text-transform: uppercase;
        }

        p {
            margin: 4rem 0;
        }
    }
    // section.benefit:before {
    //     background: inherit;
    //     top: -200px;
    //     content: '';
    //     height: 200px;
    //     left: 0;
    //     right: 0;
    //     position: absolute;
    //     transform: skewY(-3deg);
    //     transform-origin: 100%;
    //     z-index: -1;
    // }
    section.benefit:after {
        background: inherit;
        bottom: 0;
        content: '';
        height: 200px;
        left: 0;
        right: 0;
        position: absolute;
        transform: skewY(-3deg);
        transform-origin: 100%;
        z-index: -1;
    }

    .privilege__logo img {
        max-width: 400px;
    }

    .privilegeCalculator__reset {
        appearance: none;
        outline: none;
        text-decoration: underline;
        background: none;
        border: none;
        color: $primary-white;
        letter-spacing: 0.02em;
        cursor: pointer;
        padding: 1rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        // bottom: 0;
    }
    .privilegeCalculator__reset:hover {
        color: darken($primary-white, 10);
    }

    // .earningsCalculator__container {
    //     margin: 8rem 0;
    // }

    .privilege__benefitSelectionContainer {
        display: flex;
    }

    .sectionHeading__image {
        position: absolute;
        top: -220px;
    }

    .privilege__benefitButton {
        appearance: none;
        outline: none;
        background: none;
        border: none;
        background: lighten(#001e51, 10);
        padding: 2rem;
        color: lighten(#001e51, 75);
        font-size: 1.4rem;
        text-transform: uppercase;
        cursor: pointer;
    }

    .privilege__benefitButton--left {
        border-radius: 4px 0 0 4px;
        border-right: 1px solid lighten(#001e51, 5);
    }
    .privilege__benefitButton--right {
        border-radius: 0 4px 4px 0;
        border-left: 1px solid lighten(#001e51, 15);
    }

    .privilege__benefitButton--selected {
        background-color: lighten(#001e51, 25);
    }

    .privilegePurchaseCalculatorResult {
        h2 {
            text-transform: uppercase;
            font-size: 1.4rem;
            margin: 0 0 2rem 0;
            color: lighten(#001e51, 65);
        }
        font-size: 4rem;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        padding: 2rem;
    }

    .fieldContainer {
        border-color: rgba(255, 255, 255, 0.8);
        background-color: rgba(255, 255, 255, 0.1);
        height: 60px;
        .field__label {
            color: $primary-white;
            font-size: 1.5rem;
            line-height: 6rem;
        }
        .field__helper {
            color: $primary-white;
        }
        input {
            color: $primary-white;
            font-size: 3rem;
            line-height: 6rem;
        }
    }

    .projectedEarnings {
        font-size: 4rem;
        color: $success-green;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
            0 1px 3px 1px rgba(60, 64, 67, 0.16);
        display: inline-block;
        padding: 2rem;
        margin-bottom: 4rem;
        .title {
            margin: 0 0 2rem 0;
            color: #001e51;
            font-size: 1.4rem;
            text-transform: uppercase;
            font-weight: normal;
        }
        span {
            font-size: 2rem;
        }
    }

    .privilegeRewardsGrid {
        display: grid;
        grid-template-columns: 1fr 48px 1fr;
        gap: 4rem;

        .visaCard {
            max-width: 100%;
        }

        .quantity {
            position: absolute;
            bottom: 3rem;
            font-size: 6rem;
            span {
                font-size: 2rem;
            }
        }
    }

    .or {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            background: #ccc;
            border-radius: 50%;
            height: 48px;
            width: 48px;
            line-height: 48px;
            text-align: center;
            font-size: 2rem;
        }
    }

    .privilegeRewardsContainer {
        margin-top: 6rem;
    }

    .privilegeCardContainer {
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
            0 1px 3px 1px rgba(60, 64, 67, 0.16);
        position: relative;
        .privilegeCardImage {
            img {
                width: 100%;
                margin-bottom: -5px;
            }
        }

        .privilegeTripHeading {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 2rem 0;
            background: rgba(255, 255, 255, 0.5);
            font-size: 3rem;
            backdrop-filter: blur(3px);
            text-align: center;
        }
    }

    .tripDetails {
        text-align: left;
        // margin-top: 10rem;
        color: rgba(0, 0, 0, 0.75);
        letter-spacing: 0.02em;
        ul {
            li {
                font-size: 1.3rem;
                // text-align: justify;
            }
        }
    }

    .btn-toggle-details {
        // margin-top: 8rem;
        background: $primary-white;
        color: $primary-blue;
        max-width: 50%;
        text-align: left;

        .btn-toggle-details--icon {
            position: relative;
            top: 8px;
            left: 8px;

            &.rotate {
                transform: rotate(180deg);
                transition: all 0.3s ease-in;
            }

            &.no-rotate {
                transform: rotate(0deg);
                transition: all 0.3s ease-in;
            }
        }
    }

    .tabSelectorContainer {
        border: none;

        .tabSelectorButton {
            background: $primary-white;
            color: $primary-blue;
            position: relative;
            top: -2px;
        }

        .tabSelectorButton--active {
            border-bottom: 2px solid $primary-blue;
        }
    }
}
